import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from 'guards/RoleBasedGuard';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Komisyon
    {
      path: 'dashboard',
      element: (
        <RoleBasedGuard accessibleRoles={['Komisyon', 'Komisyon Sözcüsü']}>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { path: 'onay-talepleri', element: <OnayTalepleri /> },
        { path: 'onay-talepleri/:talepId/detay', element: <TalepDetay /> },
        { path: 'gelen-talepler', element: <GelenTalepler2 /> },
        { path: 'gelen-talepler/:talepId/detay', element: <TalepDetay /> },
        { path: 'gelen-talepler2', element: <GelenTalepler2 /> },
        { path: 'gelen-talepler2/:talepId/detay', element: <TalepDetay /> },
        { path: 'kullanicilar/yeni', element: <NewUser /> },
        { path: 'kullanicilar/:userId/duzenle', element: <NewUser /> },
        { path: 'talep-arsivi', element: <TalepArsiv /> },
      ],
    },

    // Komisyon, Heyet
    {
      path: 'dashboard',
      element: (
        <RoleBasedGuard accessibleRoles={['Komisyon', 'Komisyon Sözcüsü', 'Heyet']}>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { path: 'istisaredeki-talepler', element: <IstisaredekiTalepler /> },
        { path: 'istisaredeki-talepler/:talepId/detay', element: <TalepDetay /> },
      ],
    },

    // Tum kullanicilar
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/dashboard/app' replace /> },
        { path: 'app', element: <Dashboard /> },
        { path: 'taleplerim', element: <Taleplerim /> },
        { path: 'yeni-talep', element: <YeniTalep /> },
        { path: 'taleplerim/:talepId/duzenle', element: <YeniTalep /> },
        { path: 'taleplerim/:talepId/detay', element: <TalepDetay /> },
        { path: 'kullanicilar', element: <Kullanicilar /> },
      ],
    },

    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Navigate to='/dashboard/app' replace /> },
        // { element: <LandingPage /> },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}

// Auth
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Taleplerim = Loadable(lazy(() => import('../pages/Taleplerim')));
const GelenTalepler = Loadable(lazy(() => import('../pages/GelenTalepler')));
const OnayTalepleri = Loadable(lazy(() => import('../pages/OnayTalepleri')));
const GelenTalepler2 = Loadable(lazy(() => import('../pages/GelenTalepler2')));
const TalepArsiv = Loadable(lazy(() => import('../pages/TalepArsiv')));

const IstisaredekiTalepler = Loadable(lazy(() => import('../pages/IstisaredekiTalepler')));
const Kullanicilar = Loadable(lazy(() => import('../pages/Kullanicilar')));
const YeniTalep = Loadable(lazy(() => import('../pages/YeniTalep')));
const TalepDetay = Loadable(lazy(() => import('../pages/TalepDetay')));
const NewUser = Loadable(lazy(() => import('../pages/NewUser')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
